<template>
  <div class="snt-editor">
    <div
      v-if="!loading"
      class="snt-editor__wrapper">
      <div
        ref="toolbar"
        class="snt-editor__toolbar">
        <div
          v-if="isAdmin"
          class="snt-editor__responsive">
          <v-btn
            :color="isCodeTags ? 'primary' : 'default'"
            class="snt-btn--rounded px-0"
            min-width="36px"
            depressed
            @click="toggleCodeTags()">
            <v-icon>mdi-code-tags</v-icon>
          </v-btn>
        </div>
        <div class="snt-editor__viewpages">
          <v-select
            :value="viewPage"
            :items="viewPages"
            :menu-props="{
              'transition':'slide-y-transition',
              'content-class': 'menu-offset--left-0',
              'offset-y': true
            }"
            item-text="text"
            item-value="value"
            hide-details
            outlined
            dense
            @input="setViewPage($event)" />
        </div>
        <div
          v-if="!isCodeTags"
          class="snt-editor__responsive">
          <v-btn
            :color="isDevice === 'laptop' ? 'primary' : 'secondary'"
            icon
            @click="setDevice('laptop')">
            <v-icon size="20">
              mdi-monitor
            </v-icon>
          </v-btn>
          <v-btn
            :color="isDevice === 'tablet' ? 'primary' : 'secondary'"
            icon
            @click="setDevice('tablet')">
            <v-icon size="20">
              mdi-tablet
            </v-icon>
          </v-btn>
          <v-btn
            :color="isDevice === 'mobile' ? 'primary' : 'secondary'"
            icon
            @click="setDevice('mobile')">
            <v-icon size="20">
              mdi-cellphone
            </v-icon>
          </v-btn>
        </div>
        <v-spacer />
        <div
          v-if="!isCodeTags"
          class="snt-editor__theme">
          <snt-color-picker
            ref="colorPrimary"
            :value="theme.color.primary"
            size="36"
            @input="setThemeColor('primary', $event)" />
          <snt-color-picker
            ref="colorSecondary"
            :value="theme.color.secondary"
            size="36"
            @input="setThemeColor('secondary', $event)" />
          <snt-color-picker
            ref="colorAccent"
            :value="theme.color.accent"
            size="36"
            @input="setThemeColor('accent', $event)" />
        </div>
      </div>
      <v-container
        v-if="isCodeTags"
        class="pa-0"
        fluid>
        <v-row no-gutters>
          <v-col cols="8">
            <div class="snt-editor-caption caption pa-4">
              Html + Component
            </div>
            <div class="snt-codemirror__container">
              <codemirror
                v-if="file[viewPage].value"
                :value="file[viewPage].value"
                :options="codemirrorOption[file[viewPage].lang]" />
            </div>
          </v-col>
          <v-col cols="4">
            <div class="snt-editor-caption caption pa-4">
              Stylesheet
            </div>
            <div class="snt-codemirror__container">
              <codemirror
                :value="file.Stylesheet.value"
                :options="codemirrorOption[file.Stylesheet.lang]" />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div
        v-else
        id="previewPlaceholder"
        ref="previewPlaceholder"
        :class="{
          'snt-preview__container--tablet': isDevice === 'tablet',
          'snt-preview__container--mobile': isDevice === 'mobile',
        }">
        <iframe
          id="preview"
          :key="`iframe-preview`"
          :src="viewPageUrl"
          class="snt-editor-iframe"
          frameborder="0"
          allowfullscreen />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SntColorPicker from '@/components/form/SntColorPicker.vue'
import { GET_SALEPAGETEMPLATE } from '@/resources/graphql'

export default {
  components: { SntColorPicker },
  apollo: {
    salePageTemplate: () => ({
      fetchPolicy: 'network-only',
      query: GET_SALEPAGETEMPLATE,
      variables () {
        return {
          id: this.slug
        }
      },
      result ({ data: { salePageTemplate } }) {
        this.file = {
          Home: {
            value: salePageTemplate.landing,
            lang: 'html'
          },
          Products: {
            value: salePageTemplate.products,
            lang: 'html'
          },
          ProductDetail: {
            value: salePageTemplate.product,
            lang: 'html'
          },
          Cart: {
            value: salePageTemplate.cart,
            lang: 'html'
          },
          Orders: {
            value: salePageTemplate.orders,
            lang: 'html'
          },
          Order: {
            value: salePageTemplate.order,
            lang: 'html'
          },
          Checkout: {
            value: salePageTemplate.checkout,
            lang: 'html'
          },
          Stylesheet: {
            value: salePageTemplate.stylesheet,
            lang: 'css'
          }
        }
      }
    })
  },
  data () {
    return {
      isCodeTags: false,
      isDevice: 'laptop',
      file: {},
      viewPages: [
        {
          text: this.$t('home'),
          value: 'Home'
        },
        {
          text: this.$t('productItems'),
          value: 'Products'
        },
        {
          text: this.$t('productDesc'),
          value: 'ProductDetail'
        },
        {
          text: this.$t('productCart'),
          value: 'Cart'
        },
        {
          text: this.$t('checkout'),
          value: 'Checkout'
        },
        {
          text: this.$t('payment'),
          value: 'Payment'
        },
        {
          text: this.$t('orders'),
          value: 'Orders'
        },
        {
          text: this.$t('order'),
          value: 'Order'
        }
      ],
      codemirrorOption: {
        html: {
          tabSize: 2,
          lineNumbers: true,
          lineWrapping: true,
          mode: 'text/html',
          readOnly: true,
          extraKeys: { 'Ctrl-Q': function (cm) { cm.foldCode(cm.getCursor()) } },
          foldGutter: true,
          gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers']
        },
        css: {
          tabSize: 4,
          styleActiveLine: true,
          lineWrapping: true,
          lineNumbers: true,
          line: true,
          mode: 'text/css',
          keyMap: 'sublime',
          readOnly: true
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      viewPage: 'app/viewPage',
      theme: 'local/theme'
    }),
    slug () {
      return this.$route.params.slug
    },
    loading () {
      return this.$apollo.queries.salePageTemplate.loading
    },
    viewPageUrl () {
      return this.getUrl(this.viewPage)
    }
  },
  beforeDestroy () {
    window.removeEventListener('blur', this.onClickIFrame)
  },
  mounted () {
    window.addEventListener('blur', this.onClickIFrame)
  },
  methods: {
    ...mapActions({
      setViewPage: 'app/setViewPage',
      setTheme: 'local/setTheme'
    }),
    onClickIFrame () {
      const evt = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      })
      this.$refs.toolbar.dispatchEvent(evt)
      this.$refs.colorPrimary.menu = false
      this.$refs.colorSecondary.menu = false
      this.$refs.colorAccent.menu = false
    },
    toggleCodeTags () {
      this.isCodeTags = !this.isCodeTags
    },
    setThemeColor (key, value) {
      this.setTheme({
        color: this.$_.defaults({
          [key]: value
        }, this.theme.color)
      })
    },
    setDevice (isDevice) {
      this.isDevice = isDevice
    },
    getUrl (viewPage) {
      const templateUrl = `${process.env.VUE_APP_ROUTE_PREFIX}templates/${this.slug}/sale-page`
      const templateUrlSuffix = ''
      switch (viewPage) {
        case 'Products':
          return `${templateUrl}/products${templateUrlSuffix}`
        case 'ProductDetail':
          return `${templateUrl}/products/default${templateUrlSuffix}`
        case 'Cart':
          return `${templateUrl}/cart${templateUrlSuffix}`
        case 'Checkout':
          return `${templateUrl}/checkout${templateUrlSuffix}`
        case 'Payment':
          return `${templateUrl}/payment${templateUrlSuffix}`
        case 'Order':
          return `${templateUrl}/orders/default${templateUrlSuffix}`
        case 'Orders':
          return `${templateUrl}/orders${templateUrlSuffix}`
        default:
          return `${templateUrl}/${templateUrlSuffix}`
      }
    }
  }
}
</script>
<style>
  .CodeMirror {
    height: calc(100vh - 100px);
  }
  .snt-editor__viewpages fieldset {
    border: 0 !important;
  }
</style>

<style lang="scss" scoped>
  .snt-editor__wrapper {
    position: relative;
  }
  .snt-editor__toolbar {
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    min-width: 600px;
    height: 40px;
    border-bottom: 1px solid #E4E4E4;
    z-index: 10;
    transition: top .6s cubic-bezier(.645,.045,.355,1);
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 12%), 0 2px 10px 0 rgb(0 0 0 / 8%), 0 1px 20px 0 rgb(0 0 0 / 8%);
    background-color: #ffffff;
  }
  .snt-editor-caption {
    background-color:#ffffff;
    border: thin solid rgba(0, 0, 0, 0.12);
  }
  .snt-editor__viewpages {
    border-right: 1px solid #E4E4E4;
    width: 200px;
  }
  .snt-editor__responsive {
    border-right: 1px solid #E4E4E4;
    padding: 2px 4px;
  }
  .snt-editor__theme {
    display: flex;
    gap: 4px;
    border-left: 1px solid #E4E4E4;
    padding: 2px 4px;
  }
  #previewPlaceholder {
    position: relative;
    border: 0;
    // width: 100vw;
    height: calc(100vh - 40px);
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 12%), 0 2px 10px 0 rgb(0 0 0 / 8%), 0 1px 20px 0 rgb(0 0 0 / 8%);
  }
  .snt-preview__container--tablet {
    width: 768px !important;
    height: calc(100vh - 95px) !important;
    margin: 0 auto !important;
    top: 32px !important;
  }
  .snt-preview__container--mobile {
    width: 320px !important;
    height: calc(100vh - 95px) !important;
    margin: 0 auto !important;
    top: 32px !important;
  }
  .snt-editor-iframe {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    /* pointer-events: none; */
    border: 0;
    overflow: hidden;
  }

</style>
