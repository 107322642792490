<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="150"
    absolute
    offset-x>
    <template v-slot:activator="{ attrs, on }">
      <div
        class="snt-color-picker snt-btn--rounded mx-0 px-0"
        v-bind="attrs"
        :style="{
          backgroundColor: value,
          height: size + 'px',
          width: size + 'px',
        }"
        v-on="on" />
    </template>
    <v-color-picker
      :value="value"
      :dot-size="25"
      mode="hexa"
      @input="debounceOnChange($event)">
    </v-color-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '#ffffff'
    },
    size: {
      type: [Number, String],
      default: 40
    }
  },
  data () {
    return {
      menu: false
    }
  },
  created () {
    this.debounceOnChange = this.$_.debounce(this.onChange, 100)
  },
  methods: {
    onChange (event) {
      this.$emit('input', event)
    }
  }
}
</script>
<style scoped>

</style>
