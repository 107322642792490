var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":150,"absolute":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('div',_vm._g(_vm._b({staticClass:"snt-color-picker snt-btn--rounded mx-0 px-0",style:({
        backgroundColor: _vm.value,
        height: _vm.size + 'px',
        width: _vm.size + 'px',
      })},'div',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-color-picker',{attrs:{"value":_vm.value,"dot-size":25,"mode":"hexa"},on:{"input":function($event){return _vm.debounceOnChange($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }